/* base64 */
export default {
    _keyStr:"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
    encode(input){
        var output = ""; 
        var chr1, chr2, chr3, enc1, enc2, enc3, enc4; 
        var i = 0; 
        input = this.utf8_encode(input); 
        while (i < input.length) {
            chr1 = input.charCodeAt(i++); 
            chr2 = input.charCodeAt(i++); 
            chr3 = input.charCodeAt(i++); 
            enc1 = chr1 >> 2; 
            enc2 = ((chr1 & 3) << 4) | (chr2 >> 4); 
            enc3 = ((chr2 & 15) << 2) | (chr3 >> 6); 
            enc4 = chr3 & 63; 
            if (isNaN(chr2)) {
                enc3 = enc4 = 64; 
            } else if (isNaN(chr3)) {
                enc4 = 64; 
            }
            output = output + 
            this._keyStr.charAt(enc1) + this._keyStr.charAt(enc2) + 
            this._keyStr.charAt(enc3) + this._keyStr.charAt(enc4); 
        }
        return output; 
    },
    decode(str){
        var output = ""; 
        var chr1, chr2, chr3; 
        var enc1, enc2, enc3, enc4; 
        var i = 0; 
        input = input.replace(/[^A-Za-z0-9\+\/\=]/g, ""); 
        while (i < input.length) {
            enc1 = _keyStr.indexOf(input.charAt(i++)); 
            enc2 = _keyStr.indexOf(input.charAt(i++)); 
            enc3 = _keyStr.indexOf(input.charAt(i++)); 
            enc4 = _keyStr.indexOf(input.charAt(i++)); 
            chr1 = (enc1 << 2) | (enc2 >> 4); 
            chr2 = ((enc2 & 15) << 4) | (enc3 >> 2); 
            chr3 = ((enc3 & 3) << 6) | enc4; 
            output = output + String.fromCharCode(chr1); 
            if (enc3 != 64) {
                output = output + String.fromCharCode(chr2); 
            } 
            if (enc4 != 64) {
                output = output + String.fromCharCode(chr3); 
            } 
        } 
        output = this.utf8_decode(output); 
        return output; 
    },
    utf8_encode(string){
        var utftext = ""; 
        for (var n = 0; n < string.length; n++) {
            var c = string.charCodeAt(n); 
            if (c < 128) {
                utftext += String.fromCharCode(c); 
            } else if((c > 127) && (c < 2048)) { 
                utftext += String.fromCharCode((c >> 6) | 192); 
                utftext += String.fromCharCode((c & 63) | 128); 
            } else {
                utftext += String.fromCharCode((c >> 12) | 224); 
                utftext += String.fromCharCode(((c >> 6) & 63) | 128); 
                utftext += String.fromCharCode((c & 63) | 128); 
            } 
        } 
        return utftext; 
    },
    utf8_decode(utftext){
        var string = ""; 
        var i = 0; 
        var c = c1 = c2 = 0; 
        while ( i < utftext.length ) {
            c = utftext.charCodeAt(i); 
            if (c < 128) {
                string += String.fromCharCode(c); 
                i++; 
            } else if((c > 191) && (c < 224)) {
                c2 = utftext.charCodeAt(i+1); 
                string += String.fromCharCode(((c & 31) << 6) | (c2 & 63)); 
                i += 2; 
            } else {
                c2 = utftext.charCodeAt(i+1); 
                c3 = utftext.charCodeAt(i+2); 
                string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63)); 
                i += 3; 
            } 
        } 
        return string; 
    },
    dataURLtoFile (dataurl, filename) {
        let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {
          type: mime
        });
      },
      dataURLtoBlob(base64Data) {
        // console.log(base64Data, base64Data.length)
            let byteString = base64Data
            if (base64Data.split(',')[0].indexOf('base64') >= 0) {
            byteString = atob(base64Data.split(',')[1]) // base64 解码
            } else {
            byteString = unescape(base64Data.split(',')[1])
            }
            // 获取文件类型
            let mimeString = base64Data.split(',')[0].match(/:(.*?);/)[1] // mime类型
    
            // ArrayBuffer 对象用来表示通用的、固定长度的原始二进制数据缓冲区
            // let arrayBuffer = new ArrayBuffer(byteString.length) // 创建缓冲数组
            // let uintArr = new Uint8Array(arrayBuffer) // 创建视图
    
            let uintArr = new Uint8Array(byteString.length) // 创建视图
    
            for (let i = 0; i < byteString.length; i++) {
            uintArr[i] = byteString.charCodeAt(i)
            }
            // 生成blob图片
            const blob = new Blob([uintArr], {
            type: mimeString
            })
            // 使用 Blob 创建一个指向类型化数组的URL, URL.createObjectURL是new Blob文件的方法,可以生成一个普通的url,可以直接使用,比如用在img.src上
            return (blob)
        }
}